import { Suspense, lazy, useEffect } from "react"; // use to loading , loading screen until full page is load
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import MainLayout from "../layouts/main";

// config
import { DEFAULT_PATH } from "../config";
import LoadingScreen from "../components/LoadingScreen";
//import Settings from "../pages/dashboard/Settings";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    localStorage.getItem("loggedIn")
      ? {
          path: "/",
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
            { path: "app", element: <GeneralApp /> },
            // { path: "settings", element: <Settings /> },
            { path: "template", element: <TemplateComp /> },
            // { path: "call", element: <CallPage /> },
            { path: "profile", element: <ProfilePage /> },
            { path: "404", element: <Page404 /> },
            { path: "*", element: <Navigate to="/404" replace /> },
          ],
        }
      : {
          path: "/",
          element: <MainLayout />,
          children: [
            { path: "", element: <LoginPage /> },
            {
              element: <RegisterPage />,
              path: "form",
            },
            { element: <ResetPasswordPage />, path: "reset-password" },
            { element: <NewPasswordPage />, path: "new-password" },
          ],
        },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

const LoginPage = Loadable(lazy(() => import("../pages/auth/Login")));

const RegisterPage = Loadable(lazy(() => import("../pages/auth/Register")));

const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);

const NewPasswordPage = Loadable(
  lazy(() => import("../pages/auth/NewPassword"))
);

const TemplateComp = Loadable(
  lazy(() => import("../pages/dashboard/template/index"))
);

const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));

const CallPage = Loadable(lazy(() => import("../pages/dashboard/Call")));

const ProfilePage = Loadable(lazy(() => import("../pages/dashboard/Profile")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
